<template>
  <div class="row">
    <div class="col-xxl-10">
      <template v-for="(item, i) in data">    
      <div class="card gutter-b card-stretch" v-bind:key="i">
        <div class="card-header h-auto border-0">
          <!-- <div class="card-title"> -->
          <h3 class="card-title align-items-start flex-column">
            <span class="d-block text-dark font-weight-bolder"
              >{{ item.name }}</span
            >
            <span class="d-block text-muted mt-2 font-size-sm"
              >Selected Debiter Group {{ item.fsDebgroep }}</span
            >
          </h3>
          <!-- </div> -->
          
          <div class="card-toolbar"> 
          </div>
        </div>
        <div class="card-body d-flex flex-column card-spacer">
          <!-- <b-container fluid> -->
            <b-row>
              <b-col sm="2">
                <label for="SmsBody">SMS Body ({{ SmsBody.length + (item.webshopUrl + '/Account/LoginByUrl?user={DEBNR}&pass={PASSWORD}').length }}):</label>
              </b-col>
              <b-col sm="10">
                <b-form-textarea
                  id="SmsBody"
                  v-model="SmsBody"
                  placeholder="Start typing here..."
                  rows="3"
                  max-rows="8"
                ></b-form-textarea>
              </b-col>
            </b-row>
          <!-- </b-container> -->
            <b-row class="mt-5">
              <b-col sm="12">
                <p><i>"{{ SmsBody }} <br>{{ item.webshopUrl + '/Account/LoginByUrl?user={DEBNR}&pass={PASSWORD}' }}"</i></p>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <a href="javascript:void(0)" @click="UpdateDebgreop(item.companyId, item.fsDebgroep, item.sibListid, i)" class="btn btn-info font-weight-bolder font-size-sm">
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="media/svg/icons/General/Update.svg" />
                  </span>Update SIB list ({{ item.sibListid }})</a>
                  <a href="javascript:void(0)" @click="EmptyList(item.companyId, item.fsDebgroep, item.sibListid, i), showAlert(i);" class="btn btn-info font-weight-bolder font-size-sm ml-5">
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="media/svg/icons/General/Update.svg" />
                  </span>Empty list</a>      
              </b-col>
              <b-col sm="6" style="display:inline-flex;">
                <a href="javascript:void(0)" @click="sendBulkSms(item.webshopUrl + '/Account/LoginByUrl?user={DEBNR}&pass={PASSWORD}', item.companyId, item.name, item.sibListid,i)" class="btn btn-primary font-weight-bolder font-size-sm">
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="media/svg/icons/Code/Right-circle.svg" />
                  </span>Send bulk SMS</a>
                  <b-form-timepicker class="ml-5" v-model="defaultTime" minutes-step="5" now-button reset-button locale="fr" style="height: 40px; width: 200px;"></b-form-timepicker>
              </b-col>
            </b-row>
            <b-row class="mt-5" v-if="item.SMScampaignCreated || item.ImportedAllDebnrSuccess || item.dismissCountDown">
              <b-col sm="6">
                <b-alert
                :show="item.dismissCountDown"
                dismissible
                variant="success"
                @dismissed="item.dismissCountDown = 0"
                @dismiss-count-down="countDownChanged(i,$event)">
                <p>Please wait {{ item.dismissCountDown }} seconds for the list to empty?<br>{{  ResponseEmptyList }}</p>
                  <b-progress
                  variant="success"
                  :max="dismissSecs"
                  :value="item.dismissCountDown"
                  height="4px"
                  ></b-progress>
                </b-alert>   
                <b-alert
                  variant="success"
                  dismissible
                  fade
                  :show="item.ImportedAllDebnrSuccess"
                  @dismissed="item.ImportedAllDebnrSuccess=false"
                >
                  Debnr successfully imported in SIB !<br>
                  {{ ResponseUpdateDebgreop }}
                </b-alert>
              </b-col>
              <b-col sm="6">
                <b-alert
                  variant="success"
                  dismissible
                  fade
                  :show="item.SMScampaignCreated"
                  @dismissed="item.SMScampaignCreated=false"
                >
                  Campaign successfully created and scheduled !<br>
                  {{ ResponseSendBulkSms }}
                </b-alert>
              </b-col>
            </b-row>
          <!-- Start of loading animation -->
          <div class="p-10" v-if="item.loading">
          <!-- <div class="card-body"> -->
          <div class="spinner spinner-primary spinner-md spinner-center"></div>
          <p class="text-6xl font-bold text-center text-gray-500 animate-pulse mt-5">
            Loading...
          </p>
          <!-- </div> -->
          <!-- End of loading animation -->
          <!-- Start of error alert -->
          <!-- <v-alert border="top" color="red lighten-2" v-if="error.response.length > 0" dark>
            {{ error.response }}
          </v-alert> -->
          </div>
        </div>
        </div>
      </template>
    </div>
  </div>
  </template>
  
  <script>
  import axios from "axios";
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  // import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
  import 'vue-good-table/dist/vue-good-table.css'
  // import { VueGoodTable } from 'vue-good-table';
  
  
  export default {
    // name: "line-base",
    components: {
      // MixedWidget1,
      // VueGoodTable,
    },  
    data() {
      return {
        loading: true,
        // Pageloading: true,
        SMScampaignCreated: false,
        ImportedAllDebnrSuccess: false,
        error: [],
        data: [],
        SmsBody: "Get a discount by visiting our online store",
        SendWebshopUrl: true,
        charCounter: 0,
        ResponseSendBulkSms: "",
        ResponseUpdateDebgreop: "",
        ResponseEmptyList: "",
        time: '',
        defaultTime: '20:00',
        dismissSecs: 60,
        dismissCountDown: 0,
        showDismissibleAlert: false
        // lineId: this.$route.params.id,
      }
    },
    mounted() {
      // console.log(this.$route.params);
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Home > SMS Offers"}]);
      // console.log(this.lineId);
      axios
        .get("Home/getsmsspecialoffers")
        .then((response) => {
          this.data = response.data;
          // console.log(this.data)
          for (let i = 0; i < this.data.length; i++) {
           this.$set(this.data[i], "dismissCountDown", 0);
           this.$set(this.data[i], "showDismissibleAlert", false);
           this.$set(this.data[i], "SMScampaignCreated", false);
           this.$set(this.data[i], "ImportedAllDebnrSuccess", false);
           this.$set(this.data[i], "loading", false);
          }
          // this.Pageloading = false;
        })
        .catch((error) => {
          this.error.response = "Error msg";
          // this.error.length = 1;
          // console.log(this.error)
          // this.Pageloading = false;
          throw error;
        });
    },
    methods: {
      countDownChanged(index,dismissCountDown) {
          this.data[index].dismissCountDown = dismissCountDown
      },
      showAlert(index) {
        this.data[index].dismissCountDown = this.dismissSecs
        this.data[index].showDismissibleAlert = true
      },
      getMin(){
        return this.defaultTime.split(":")[1];
      },
      getHour(){
        return this.defaultTime.split(":")[0];
      },
      // formatNumbers: function(value) {
      //   return value.toLocaleString('nl-NL');
      // }
      sendBulkSms: function(webshopurl, companyId, name, listId, index) {
        this.charCounter = this.SmsBody.length + webshopurl.length;
        this.data[index].loading = true;
        // this.SmsBody = this.SmsBody + " " + data
        // this.startdate = (new Date(new Date().getFullYear(), new Date().getMonth()-1, 2)).toISOString().substr(0, 10);
        // this.enddate = (new Date(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString().substr(0, 10);
        // this.dates = [this.startdate, this.enddate];
        // console.log(this.SmsBody + " " + webshopurl);
        // console.log(this.charCounter);
        axios
          // .post('/home/turnover2?CompanyId=' + companyId + '&campaignName=' + name + '&SmsBody=' + this.SmsBody + " " + webshopurl +'&delayMinutes=120&listId=' + listId)
          .post('/home/createSMScampaign', { CompanyId : companyId, Name : name, SmsBody : this.SmsBody + " " + webshopurl, Minutes : this.getMin(),Hours : this.getHour(), SibListid : listId })
          .then((response) => {
            // this.data = response.data;
            // console.log(response.data)
            this.ResponseSendBulkSms = response.data;
            this.data[index].SMScampaignCreated = true;
            this.data[index].loading = false;
          })
          .catch((error) => {
            this.error.response = "Error msg";
            // this.error.length = 1;
            // console.log(this.error)
            this.data[index].loading = false;
            throw error;
          });
          
      },
      UpdateDebgreop: function(companyId, FsDebgroep, SibListid, index) {
        this.data[index].loading = true;
        // console.log(SibListid);
        axios
          .post('/home/importalldebnrinSIB', { CompanyId : companyId, FsDebgroep : FsDebgroep, SibListid : SibListid })
          .then((response) => {
            // this.data = response.data;
            // console.log(response.data)
            this.ResponseUpdateDebgreop = response.data;
            this.data[index].ImportedAllDebnrSuccess = true;
            this.data[index].loading = false;
          })
          .catch((error) => {
            this.error.response = "Error msg";
            // this.error.length = 1;
            // console.log(this.error)
            this.data[index].loading = false;
            throw error;
          });
      },
      EmptyList: function(companyId, FsDebgroep, SibListid, index){
        this.data[index].loading = true;
        axios
        .post('/home/emptysmslist', { CompanyId : companyId, FsDebgroep : FsDebgroep, SibListid : SibListid })
          .then((response) => {
            this.data[index].loading = false;
            this.ResponseEmptyList = response.data;
            this.showDismissibleAlert = true;
            
          })
          .catch((error) => {
            this.error.response = "Error msg";
            this.data[index].loading = false;
            throw error;
          });
        
      }
    },
  };
  </script>
  